import { useCallback } from 'react';
import { useApi } from '../api/ApiProvider';
import Stripe from 'stripe';
import { SubscriptionPlanKey } from './orders';
import useSWR from 'swr';

export type IUser = {
  id: string;
  email: string;
  name?: string | null;
  city: string;
  password: string;
  resetPasswordToken: string;
  resetPasswordExpires: string;
  dateCreated: string | number;
  pushTokens: string[];
  emailVerified: boolean;
  stripeId?: string;
  subscription: {
    planId: SubscriptionPlanKey;
    // Stripe fields
    id: string;
    status: Stripe.Subscription.Status;
    expiration: string | number;
  };
};

export const getUser = api => () => api.get('/v1/user', true);

export const saveUser = api => params => api.post('/v1/user', true, params);

export function useUser() {
  const api = useApi();
  const { data, isLoading, mutate } = useSWR<IUser>('/user', getUser(api), {
    shouldRetryOnError: false,
  });

  return {
    data,
    isLoading,
    mutate,
    updateUser: useCallback(
      async (newUser: IUser & { pushToken?: string }) => {
        await saveUser(api)(newUser);
        mutate(newUser, { revalidate: false });
      },
      [mutate]
    ),
  };
  // return useSWR<IUser>('/user', getUser(api));
}
