import { createContext, useContext, useState } from 'react';
import useSWR from 'swr';
import { useApi } from '../api/ApiProvider';
import Stripe from 'stripe';
import useSWRMutation from 'swr/mutation';
import { useUser } from './user';

export type SubscriptionPlanKey = 'FREE' | 'PREMIUM';

export type SubscriptionPlan = {
  id: SubscriptionPlanKey;
  terms: number;
  amount: number;
  trialPeriod?: number;
  priceId?: string;
};

const OrdersContext = createContext<{
  paymentModalIsOpen: boolean;
  setPaymentModalIsOpen: (isOpen: boolean) => void;
}>(null);

export function OrdersProvider({ children }) {
  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);

  return (
    <OrdersContext.Provider
      value={{ paymentModalIsOpen, setPaymentModalIsOpen }}
    >
      {children}
    </OrdersContext.Provider>
  );
}

export function useOrders() {
  return useContext(OrdersContext);
}

export function useGetSubscription() {
  const api = useApi();

  return useSWR<{
    plan: SubscriptionPlan;
    subscription: Stripe.Subscription;
  }>('/v1/orders/subscription', api.get);
}

export function useCancelSubscription() {
  const api = useApi();
  const user = useUser();

  return useSWRMutation(
    '/v1/orders/subscription',
    () => api.remove('/v1/orders/subscription/cancel'),
    {
      onSuccess() {
        user.mutate();
      },
    }
  );
}
