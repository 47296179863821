import dayjs from '../utils/dayjs';
import { UNITS } from './constants';

// Checks if offering1 dates are past or same relative to offering2 dates
// -----1---2---1---2-------> true
// -----1---2------12-------> true
// -----1---1---2---2-------> true
// -----12------12----------> true
export const isPastOrSameOfferingDates = (offering1, offering2 = offering1) => {
  const date1 =
    offering1.specialPriceEnd ||
    offering1.specialPriceStart ||
    offering1.scanDate;
  const date2 =
    offering2.specialPriceEnd ||
    offering2.specialPriceStart ||
    offering2.scanDate;

  return dayjs(date1).isSameOrBefore(dayjs(date2), 'd');
};

export const isFutureOffering = offering =>
  offering.specialPriceStart &&
  dayjs(offering.specialPriceStart).isAfter(dayjs());

export const isExpiredOffering = offering =>
  offering.specialPriceEnd && dayjs().isAfter(offering.specialPriceEnd, 'd');

// check if today falls in between the promo range
// `specialPriceEnd` could be undefined, in that case moment() will check only that we are past the start day
export const isActiveOffering = ({ specialPriceStart, specialPriceEnd }) =>
  dayjs().isBetween(specialPriceStart, specialPriceEnd, 'day', '[]');

export const getSpecialPrice = offering => {
  const { specialPrice, specialPriceStart } = offering;

  if (!specialPrice || !specialPriceStart) {
    return;
  }
  if (isActiveOffering(offering)) {
    return specialPrice;
  }
};

// multiplier is used in SavingsScreen to format for the total quantity added in cart
export const getFinalPrice = (offering, multiplier = 1) => {
  const specialPrice = getSpecialPrice(offering);

  if (specialPrice) {
    return multiplier * specialPrice;
  }

  // Price is when you buy `offerQuantity` quantity.
  // So we should divide by it to get the single unit price.
  return multiplier * (offering.price / (offering.offerQuantity || 1));
};

export const getTotalWeight = ({ quantityPerUnit, weight, offering }) =>
  (quantityPerUnit || 1) *
  ((offering.offerQuantity > 1 &&
    isActiveOffering(offering) &&
    offering.offerQuantity) ||
    1) *
  weight;

export const getUnitPrice = productVariant =>
  getFinalPrice(productVariant.offering) / getTotalWeight(productVariant);

// returns weight for the smallest unit (g, ml, unit)
// ex. 0.250(l) * 2 --> { weight: 500, unit: 'ml' }
export const prettyWeightUnit = (weight, unit, multiplier = 1) => {
  let finalWeight = weight * multiplier;
  let finalUnit = unit;

  if (finalWeight < 1) {
    // convert to g/ml
    // 0.1l, becomes 100ml
    finalWeight = finalWeight * 1000;

    if (unit === UNITS.KG) {
      finalUnit = UNITS.G;
    }
    if (unit === UNITS.L) {
      finalUnit = UNITS.ML;
    }
    if (unit === UNITS.M) {
      finalUnit = UNITS.CM;
    }
  }

  return { weight: finalWeight, unit: finalUnit };
};

export const getPriceDiscount = offering => {
  const specialPrice = getSpecialPrice(offering);

  if (specialPrice) {
    return offering.price - specialPrice;
  }
};

export const getPriceDiscountPercentage = (price, specialPrice) =>
  specialPrice ? ((specialPrice - price) / price) * 100 : 0;

export const getSavings = (a, b, totalQty = 1) => {
  const priceA = getFinalPrice(a.offering);
  const priceB = getFinalPrice(b.offering);

  const weightSavings = qty => {
    const weightA = getTotalWeight(a);
    const weightB = getTotalWeight(b);

    if (weightA < weightB) {
      // difference of their weight
      const weightDiff = weightB - weightA;
      // the cost of the weight diff (using A's unit price)
      const diffPrice = weightDiff * a.offering.unitPrice;
      // calc A's price if it was to weight as much as B
      const priceAweightB = priceA + diffPrice;

      return {
        amount: qty * (priceAweightB - priceB),
      };
    }

    if (weightA > weightB) {
      const weightDiff = weightA - weightB;
      const diffPrice = weightDiff * b.offering.unitPrice;
      const priceBweightA = priceB + diffPrice;

      return {
        amount: qty * (priceA - priceBweightA),
      };
    }

    // same weight
    return {
      amount: qty * (priceA - priceB),
    };
  };

  const offerQuantityA = a.offering.offerQuantity || 1;
  const offerQuantityB = b.offering.offerQuantity || 1;
  if (offerQuantityA !== offerQuantityB) {
    return weightSavings(totalQty);

    if (offerQuantityB > offerQuantityA) {
      // get base prices for single qty
      const singlePriceA = priceA / offerQuantityA;
      const singlePriceB = priceB / offerQuantityB;

      const qtyToNextOffer = totalQty % offerQuantityB;
      const nextTotal = totalQty + qtyToNextOffer;

      return {
        amount: nextTotal * (singlePriceA - singlePriceB),
        qtyToNextOffer,
        nextTotal,
      };

      // v1

      // // how many times the offerQuantity has been reached
      // const quo = Math.floor(qty / b.offering.offerQuantity);
      //
      // // needed qty to qualify for the next offer
      // const qtyToNextOffer = (quo + 1) * b.offering.offerQuantity - qty;
      //
      // const amount =
      //   // price at the next offer (A)
      //   (qty + qtyToNextOffer) * singlePriceA -
      //   // price at the next offer (B)
      //   (qty + qtyToNextOffer) * singlePriceB -
      //   // remove already redeemed savings
      //   (quo * b.offering.offerQuantity * singlePriceA -
      //     quo * b.offering.offerQuantity * singlePriceB);
      //
      // // show only
      // // if (qtyToNextOffer === b.offering.offerQuantity) {
      // //   return {};
      // // }
      //
      // return {
      //   amount,
      //   qtyToNextOffer, // : qtyToNextOffer === offerQuantity ? 0 : qtyToNextOffer,
      // };
    }
  }

  return weightSavings(totalQty);
};
