import { createContext, useContext, useMemo } from 'react';
import { createApi } from './api';

const ApiContext = createContext(null);

export function ApiProvider({
  children,
  baseUrl,
  storage,
  fetch,
  apiToken,
}: {
  children: any;
  baseUrl?: string;
  storage?: any;
  fetch?: any;
  apiToken?: string;
}) {
  const api = useMemo(
    () =>
      createApi({
        baseUrl,
        storage,
        fetch,
        apiToken,
      }),
    []
  );

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
}

export function useApi(): {
  get: (url: string, secure?: boolean) => Promise<any>;
  remove: (url: string, secure?: boolean) => Promise<any>;
  post: (
    url: string,
    secure: boolean,
    body: Record<string, any>
  ) => Promise<any>;
  persistJWT: (tokens: any) => Promise<any>;
  removeJWT: () => void;
} {
  return useContext(ApiContext);
}
