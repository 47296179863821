import classNames from 'classnames';
import {
  ButtonHTMLAttributes,
  ComponentPropsWithoutRef,
  FC,
  useState,
} from 'react';
import Spinner from './Spinner';

export default function Button({
  children,
  className,
  textClassName,
  variant = 'primary',
  size = 'md',
  as = 'button',
  iconLeft,
  iconRight,
  loading,
  confirm,
  onClick,
  ...props
}: {
  variant?: 'primary' | 'secondary' | 'outline' | 'link';
  size?: 'sm' | 'md' | 'lg';
  href?: string;
  as?: any;
  iconLeft?: any;
  iconRight?: any;
  loading?: boolean;
  confirm?: boolean;
  textClassName?: string;
} & ButtonHTMLAttributes<'button'>) {
  const [showConfirm, setShowConfirm] = useState(false);
  const Component = as as any;

  const onClickHandler = e => {
    if (showConfirm) {
      // Confirmed
      setShowConfirm(false);
      return onClick?.(e);
    }
    if (confirm) {
      return setShowConfirm(true);
    }
    return onClick?.(e);
  };

  const onCancel = e => {
    setShowConfirm(false);
  };

  if (showConfirm) {
    return (
      <div className="text-center">
        <span className="font-semibold text-xs">Сигурни ли сте?</span>
        <div className="flex justify-evenly gap-3">
          <Component
            className={classNames(
              `rounded disabled:opacity-50 justify-center focus:ring-2 !w-auto flex-1`,
              'bg-white border border-stone-300 hover:bg-stone-50 hover:border-stone-400 hover:shadow',
              {
                // 'bg-white border border-stone-300 hover:bg-stone-50 hover:border-stone-400 hover:shadow':
                //   variant === 'primary',
                // 'bg-white border border-stone-300 hover:bg-stone-50 hover:border-stone-400':
                //   variant === 'outline',
                // 'rounded bg-black bg-opacity-20 text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75':
                //   variant === 'secondary',
                // 'bg-white/50 hover:underline': variant === 'link',
                // Sizes
                'py-1 px-4': size === 'sm',
                'py-2 px-6': size === 'md',
                'py-3 px-8': size === 'lg',
              }
              // className
            )}
            onClick={onClickHandler}
            {...props}
          >
            {loading && <Spinner height={6} className="mr-1" />}
            {iconLeft}
            <span
              className={classNames('text-red-600 font-semibold', {
                // 'text-primary-gradient font-bold hover:no-underline':
                //   variant === 'primary',
                // 'text-white font-semibold hover:no-underline':
                //   variant === 'secondary',
                // 'text-gray-800 font-semibold hover:no-underline':
                //   variant === 'outline',
                // 'text-secondary-700 font-semibold': variant === 'link',
                // Sizes
                'text-sm': size === 'sm',
                '': size === 'md',
                'text-lg': size === 'lg',
              })}
            >
              Да
            </span>
            {iconRight}
          </Component>
          <button
            className={classNames(
              'rounded inline-flex items-center justify-center focus:ring-2 flex-1',
              'bg-white border border-stone-300 hover:bg-stone-50 hover:border-stone-400 hover:shadow',
              {
                // Sizes
                'py-1 px-4': size === 'sm',
                'py-2 px-6': size === 'md',
                'py-3 px-8': size === 'lg',
              }
            )}
            onClick={onCancel}
          >
            <span className="font-semibold">Не</span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <Component
      className={classNames(
        `rounded inline-flex items-center disabled:opacity-50 justify-center focus:ring-2`,
        {
          'bg-white border border-stone-300 hover:bg-stone-50 hover:border-stone-400 hover:shadow':
            variant === 'primary',
          'bg-white border border-stone-300 hover:bg-stone-50 hover:border-stone-400':
            variant === 'outline',
          'rounded bg-black bg-opacity-20 text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75':
            variant === 'secondary',
          'bg-white/50 hover:underline': variant === 'link',
          // Sizes
          'py-1 px-4': size === 'sm',
          'py-2 px-6': size === 'md',
          'py-3 px-8': size === 'lg',
        },
        className
      )}
      onClick={onClickHandler}
      {...props}
    >
      {loading && <Spinner height={6} className="mr-1" />}
      {iconLeft}
      <span
        className={classNames(
          {
            'text-primary-gradient font-bold hover:no-underline':
              variant === 'primary',
            'text-white font-semibold hover:no-underline':
              variant === 'secondary',
            'text-gray-800 font-semibold hover:no-underline':
              variant === 'outline',
            'text-secondary-700 font-semibold': variant === 'link',
            // Sizes
            'text-sm': size === 'sm',
            '': size === 'md',
            'text-lg': size === 'lg',
          },
          textClassName
        )}
      >
        {children}
      </span>
      {iconRight}
    </Component>
  );
}
