export type IApi = {
  get: <T>(uri: string) => Promise<T>;
};

export class ApiError extends Error {
  url;
  code;
  refreshToken;

  constructor(message, { url, code, refreshToken }) {
    super(message || '');
    this.name = `ApiError (${code})`;
    this.message = message || '';
    this.url = url;
    this.code = code;
    this.refreshToken = refreshToken;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message || '').stack;
    }
  }

  toJSON() {
    return {
      name: this.name,
      message: this.message,
      url: this.url,
      code: this.code,
    };
  }
}

export function createApi({
  fetch = window.fetch,
  baseUrl,
  storage,
  apiToken,
}) {
  async function persistJWT(tokens) {
    try {
      return await storage.setItem('tokens', JSON.stringify(tokens));
    } catch (err) {
      console.error(err);
    }
  }

  const getJWT = async () => {
    try {
      return JSON.parse(await storage.getItem('tokens'));
    } catch (e) {
      return null;
    }
  };

  const removeJWT = async () => storage.removeItem('tokens');

  let refreshingTokenPromise: Promise<string>;
  async function api(
    uri,
    secure = true,
    { headers = {}, shouldFailInsecure = false, timeout = 8000, ...params } = {}
  ) {
    const req = async () => {
      let tokens;

      if (typeof apiToken === 'string') {
        headers['Authorization'] = `Bearer ${apiToken}`;
      } else if (typeof secure === 'string') {
        headers['Authorization'] = `Bearer ${secure}`;
      } else if (secure === true) {
        if (refreshingTokenPromise) {
          // wait for previous refresh request
          await refreshingTokenPromise;
        }
        // get tokens
        tokens = await getJWT();

        if (tokens) {
          // put accessToken into Authorization header
          headers['Authorization'] = `Bearer ${tokens.accessToken}`;
        } else if (shouldFailInsecure) {
          // fail when there are no tokens unless that's explicitly allowed
          throw new ApiError(
            `Request marked as secure but no tokens are available.`,
            // @ts-ignore
            {
              url: uri,
              code: 'no_tokens',
            }
          );
        }
      }

      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), timeout);

      return fetch(`${baseUrl}${uri}`, {
        headers,
        signal: controller.signal,
        ...params,
      })
        .then(async res => {
          const resBody = await res.text().then(text => {
            try {
              return JSON.parse(text);
            } catch (err) {
              return text;
            }
          });

          if (!res.ok) {
            throw {
              url: uri,
              code: res.status,
              message: resBody?.error?.message || resBody?.message || resBody,
            };
          }

          return resBody;
        })
        .catch(err => {
          if (err.name === 'AbortError') {
            err.code = 'timeout';
          }
          throw new ApiError((err && err.message) || '', {
            url: uri,
            code: err?.code || 'server_offline',
            refreshToken: tokens && tokens.refreshToken,
          });
        })
        .finally(() => {
          clearTimeout(timeoutId);
        });
    };

    try {
      // 1st try
      return await req();
    } catch (e) {
      // 401 and refreshToken is available
      if (e.code === 401 && e.refreshToken) {
        // Refresh tokens (if not already)
        if (!refreshingTokenPromise) {
          refreshingTokenPromise = postRefresh(e.refreshToken)
            .then(res => {
              persistJWT(res.tokens);
              return res;
            })
            .finally(() => (refreshingTokenPromise = null));
        }
        // Wait for fresh tokens
        try {
          await refreshingTokenPromise;
        } catch (refreshErr) {
          // refresh failed
          e.code = `refresh_failed (${refreshErr.code})`;
          // remove any leftover tokens
          await removeJWT();
          throw e;
        }
        // Refresh OK. Retry request with new tokens.
        return await req();
      }
      // rethrow original error
      throw e;
    }
  }

  // @ts-ignore
  const get = (...args) => api(...args);

  // @ts-ignore
  const post = (
    url,
    secure,
    { body, signal, ...data }: Record<string, any> = {}
  ) => {
    let params;

    // multipart/form-data
    if (body) {
      params = { body };
    } else {
      // json
      params = {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };
    }

    return api(url, secure, {
      method: 'POST',
      ...params,
      signal,
    });
  };

  // @ts-ignore
  const put = (url, secure, { body, signal, ...data } = {}) => {
    let params;

    if (body && body instanceof FormData) {
      // multipart/form-data
      params = { body };
    } else {
      // json
      params = {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };
    }

    return api(url, secure, {
      method: 'PUT',
      ...params,
      signal,
    });
  };

  const remove = (url, secure = true, params) =>
    api(url, secure, {
      method: 'DELETE',
      ...params,
    });

  const postRefresh = refreshToken =>
    post(`/v1/auth/refresh`, false, { refreshToken });

  return {
    get,
    post,
    put,
    remove,
    getJWT,
    persistJWT,
    removeJWT,
  };
}
