import {
  IImport,
  ImportsSearchQuery,
  createApi,
  searchImports,
} from '@mm/common';
import nodeFetch from 'node-fetch';
import cache from './cache';

// @ts-ignore
const api = createApi({
  baseUrl: process.env.NEXT_PUBLIC_API_URL,
  storage: typeof window !== 'undefined' ? window.localStorage : undefined,
  fetch: (typeof window !== 'undefined' && window.fetch) || nodeFetch,
});

export const getAllImports = async (
  filters: ImportsSearchQuery = {}
): Promise<IImport[]> => {
  const key = 'all-' + JSON.stringify(filters);
  if (process.env.NODE_ENV === 'production' && cache.get(key)) {
    return cache.get(key);
  }

  let result = [];
  let totalPages,
    currentPage = 1;

  while (!totalPages || totalPages + 1 > currentPage) {
    // console.log(`Adding page ${currentPage}`);
    const res = await searchImports(api)({
      ...filters,
      limit: 5000,
      page: currentPage,
    });
    totalPages = res.totalPages;
    currentPage++;

    result = result.concat(res.docs);
  }

  cache.set('allImports', result);
  return result;
};

export default api;
