import { getStripe } from '@/utils/stripe';
import Modal from '../Modal';
import Alert from '../Alert';
import useSWRImmutable from 'swr/immutable';
import { useRouter } from 'next/router';

export default function PaymentStatusModal() {
  const router = useRouter();

  const paymentIntentSecret = new URLSearchParams(
    typeof window !== 'undefined' ? window.location.search : ''
  ).get('payment_intent_client_secret');

  const paymentIntentQuery = useSWRImmutable(
    paymentIntentSecret && ['paymentIntent', paymentIntentSecret],
    () =>
      getStripe().then(stripe =>
        stripe?.retrievePaymentIntent(paymentIntentSecret as string)
      )
  );

  const setupIntentSecret = new URLSearchParams(
    typeof window !== 'undefined' ? window.location.search : ''
  ).get('setup_intent_client_secret');

  const setupIntentQuery = useSWRImmutable(
    setupIntentSecret && ['setupIntent', setupIntentSecret],
    () =>
      getStripe().then(stripe =>
        stripe?.retrieveSetupIntent(setupIntentSecret as string)
      )
  );

  const paymentIntent = paymentIntentQuery.data?.paymentIntent;
  const setupIntent = setupIntentQuery.data?.setupIntent;

  if (!paymentIntent && !setupIntent) return null;

  const renderMessage = () => {
    // if (!intent) return null;
    if (paymentIntent) {
      if (paymentIntent.status === 'succeeded') {
        return (
          <Alert success title="Успешно плащане!">
            Вашият абонамент ще бъде активен до няколко минути.
          </Alert>
        );
      }
      if (paymentIntent.status === 'processing') {
        return (
          <Alert warning title="Плащането се обработва...">
            Вашият абонамент ще бъде активен след потвърждение на плащането.
          </Alert>
        );
      }
      return (
        <Alert error title="Плащането не беше успешно.">
          {paymentIntent?.last_payment_error?.message}
        </Alert>
      );
    }

    // TODO:
    if (setupIntent) {
      if (setupIntent.status === 'succeeded') {
        return (
          <Alert success title="Успешно плащане!">
            Вашият абонамент ще бъде активен до няколко минути.
          </Alert>
        );
      }
      if (setupIntent.status === 'processing') {
        return (
          <Alert warning title="Плащането се обработва...">
            Вашият абонамент ще бъде активен след потвърждение на плащането.
          </Alert>
        );
      }
      return (
        <Alert error title="Плащането не беше успешно.">
          {setupIntent.last_setup_error?.message}
        </Alert>
      );
    }

    return null;
  };

  return (
    <Modal
      className="max-w-xl p-6"
      isOpen
      onClose={() => {
        router.replace(window.location.pathname);
        // @ts-ignore
        paymentIntentQuery.mutate({});
        // @ts-ignore
        setupIntentQuery.mutate({});
      }}
    >
      <>
        <h3 className="text-md font-bold leading-6 mb-6">Абонамент</h3>
        {renderMessage()}
      </>
    </Modal>
  );
}
