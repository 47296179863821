import { loadStripe, Stripe } from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null>;

export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      (process.env.NEXT_PUBLIC_STRIPE_PK as string) ||
        'pk_test_51P3Z1eG6Yj3DM43cDHiLSD2skobW3lLP3a4BtikovUBAB5HKQoh0b4PSIEa8HgQeXPxUkg0VGUvTL2lEun9yfzQD00w0OcoJzV'
    );
  }
  return stripePromise;
};
