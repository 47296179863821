import i18n from 'i18n-js';
import { UNITS } from '../product-variants/constants';
import {
  isActiveOffering,
  getSpecialPrice,
  prettyWeightUnit,
  getPriceDiscountPercentage,
} from '../product-variants/utils';

i18n.fallbacks = true;
i18n.missingBehaviour = 'guess';
i18n.locale = 'bg-BG';

i18n.translations = {
  'en-US': {
    auth: {
      reset: {
        email: {
          subject: `Password Reset Token`,
          text:
            `You are receiving this because you (or someone else) have requested the reset of the password for your MySupermarket account.\n\n` +
            'Please paste the following token into the app forgot password section to complete the process:\n\n' +
            'Reset password token: <strong>{{token}}</strong> \n\n' +
            'If you did not request this, please ignore this email and your password will remain unchanged.\n',
        },
      },
    },
  },
  'bg-BG': {
    routes: {
      HomeTab: 'Търси',
      ShoppingListsTab: 'Списъци',
      ShoppingLists: 'Списъци',
      ShoppingListCreate: 'Нов списък',
      Profile: 'Моят профил',
      FavoritesTab: 'Любими',
      Favorites: 'Любими търсения',
      SettingsTab: 'Още',
      Settings: 'Настройки',
      Terms: 'Общи Условия',
      Privacy: 'Политика за данни',
      SignUp: 'Нов профил',
      ForgotPassword: 'Забравена парола',
      CategoriesList: 'Категории',
      Contacts: 'Връзка с нас',
    },
    common: {
      title: 'MySupermarket',
      errors: {
        title: {
          default: 'Грешка',
          fb_login: 'Неуспешен вход с Facebook',
          apple_login: 'Неуспешен вход с Apple',
        },
        default: 'Код: {{code}}',
        400: 'Невалидна заявка.',
        401: 'Влез в твоя профил за да изпълниш тази заявка.',
        403: 'Влез в твоя профил за да изпълниш тази заявка.',
        404: '404',
        500: 'Системна грешка.',
        '/auth/login': {
          401: 'Грешен email или парола.',
        },
        '/auth/forgot/reset': {
          400: 'Невалиден или изтекъл токен.',
          401: 'Невалиден или изтекъл токен.',
        },
        timeout: 'Изтекла заявка.',
        fb_login: '{{message}}',
        apple_login: '{{message}}',
        server_offline: 'Няма връзка със сървъра.',
        offline: {
          1: 'Проблем с връзката',
          3: 'Някои от функциите може да не са достъпни.',
        },
      },
      back: 'Назад',
      noResults:
        'Няма продукти, отговарящи на вашето търсене. Опитайте с различни филтри.',
      noDeals: 'В момента няма активни промоции. Проверете отново по-късно.',
      copyright: 'Съни Кети ЕООД',
      submit: 'Изпрати',
      save: 'Запази',
      close: 'Затвори',
      clear: 'Изчисти',
      ok: 'OK',
      saveChanges: 'Запази промените',
      total: 'Общо',
      deleteAlert: {
        title: 'Изтриване',
        message: 'Сигурни ли сте, че искате да изтриете "{{title}}"?',
      },
      viewAll: 'Виж всички',
      viewMore: 'Виж още',
      searchPlaceholder: 'Търси...',
      loading: 'Зареждане...',
    },
    units: {
      [UNITS.UNIT]: {
        one: '1бр.',
        other: '{{count}}бр.',
        zero: 'бр.',
      },
      [UNITS.L]: {
        one: '1л.',
        other: '{{count}}л.',
        zero: 'л.',
      },
      [UNITS.ML]: {
        one: '1мл.',
        other: '{{count}}мл.',
        zero: 'мл.',
      },
      [UNITS.KG]: {
        one: '1кг.',
        other: '{{count}}кг.',
        zero: 'кг.',
      },
      [UNITS.G]: {
        one: '1гр.',
        other: '{{count}}гр.',
        zero: 'гр.',
      },
      [UNITS.M]: {
        one: '1м.',
        other: '{{count}}м.',
        zero: 'м.',
      },
    },
    price: '{{price}}лв.',
    offers: 'Оферти',
    offersDescription: 'Оферти',
    related: 'Подобни',
    relatedDescription: 'Подобни Оферти',
    specialPriceStartEnd: 'от {{specialPriceStart}} до {{specialPriceEnd}}',
    specialPriceEnd: 'до {{specialPriceEnd}}',
    lastSpecialPriceEnd: 'актуална към {{specialPriceEnd}}',
    offer: {
      multi: '{{offerQuantity}} за 1',
    },
    swapped: `Продуктът от списъка беше сменен.`,
    swappedSaved: `Спестихте {{amount}}лв.`,
    bestPrice: 'Най-добра цена',
    shoppingLists: {
      listTab: 'Списък',
      listByShopTab: 'По Магазини',
      defaultListTitle: 'Списък с покупки',
      manageLists: 'Управление на списъци',
      noItems: 'Няма добавени продукти в списъка.',
      remove: 'Изтрий',
      removeAlert: 'Изтриване на списъка?',
      new: 'Нов списък',
      select: 'Избран списък',
      title: 'Име на списъка',
      addToCart: 'Добави в списък',
      swapAndSave: 'Кликни и спести с тези оферти:',
      noSwapAndSave: 'Браво! Това е най-изгодната оферта в момента.',
      saveSummary: 'По-добри оферти',
      saveRibbon: '{{amount}}лв.',
      saveOffer: 'Още {{qty}}бр. = -{{amount}}лв.',
      unavailableItemsTotal: {
        zero: 'Неналичен',
        one: '1 неналичен',
        other: '{{count}} неналични',
      },
    },
    auth: {
      login: 'Вход',
      logout: 'Изход',
      name: 'Име',
      city: 'Град',
      email: 'Email',
      invalidEmail: 'Невалиден email',
      password: 'Парола',
      newPassword: 'Нова парола',
      loginOrSignup: 'Вход или регистрация',
      captionGuest: 'Влез в профила си за да видиш тази страница',
      haveAnAccount: 'Вече имаш профил?',
      forgotPassword: 'Забравена?',
      signUpEmail: 'Нова Регистрация',
      signInFacebook: 'Вход с Facebook',
      signInGoogle: 'Вход с Google',
      createAccount: 'Създай профил',
      createAccountSuccess: 'Вашият профил беше създаден успешно.',
      // reset password
      reset: {
        sendToken: 'Изпрати инструкции',
        haveCode: 'Имаш код за сигурност?',
        tokenSent:
          'Инструкции как да промените паролата си бяха изпратени на {{email}}.',
        resetTokenPlaceholder: 'Код за сигурност',
        changePasswordTitle: 'Промяна на парола',
        changePassword: 'Промени парола',
        resetSuccess: 'Паролата беше променена успешно.',
        email: {
          subject: `Промяна на парола`,
          line1: `За Вашия профил в MySupermarket е пусната заявка за забравена парола.`,
          line2:
            'За да промените паролата си, въведете този код за сигурност в приложението:',
          line3:
            'В случай че не сте заявили промяна на паролата, моля игнорирайте този имейл.',
        },
      },
      verify: {
        email: {
          subject: `Потвърдете Вашия профил`,
          line1: `Кликнете на бутона за да потвърдите Вашия профил.`,
        },
      },
    },
    home: {
      todayDeals: 'Оферти',
      shopCategory: 'Категории',
    },
    search: {
      inputPlaceholder: 'Търси по име или марка...',
    },
    favorites: {
      title: 'Любими',
      viewAll: 'Виж всички',
      noFavorites: 'Нямате добавени любими продукти.',
      info: 'Най-добрите цени на любимите ти продукти. Ще те известяваме при нови промоции.',
      email: {
        favSearch: {
          title: 'Любими търсения',
        },
      },
    },
    shop: 'Магазин',
    category: 'Категория',
    filters: 'Филтри',
    deals: 'Промоции',
    settings: {
      account: 'Моят акаунт',
      subscription: 'Абонамент',
      faq: 'Често задавани въпроси',
      terms: 'Общи условия',
      privacy: 'Политика за поверителност',
      contact: 'Връзка с нас',
    },
    contacts: {
      submitted:
        'Съобщението Ви беше изпратено успешно! Очаквайте обратна връзка, благодарим Ви!',
      msg: 'Съобщение',
    },
    profile: {},
    categories: {
      home: 'За Дома',
      // todo translate all categories keys
      viewAll: 'Всичко от "{{category}}"',
    },
    pushNotifications: {
      newPromo: {
        title: '🛒 Промоция в {{shop}}',
        body: '{{name}} за {{offer}}',
      },
      newPromoGeneric: {
        title: '🛒 Промоции на любими продукти.',
        body: 'Отстъпки до {{percentageDiscount}}.',
      },
      favSearch: {
        title: '🛒 Нови оферти по запазено търсене',
        body: '{{name}}',
      },
      favSearchAggregated: {
        title: '🛒 Нови оферти по запазени търсения',
        body: '{{name}}',
      },
    },
    cities: {
      city: 'Град',
      regionShort: 'обл.',
    },
    detailsDisclaimer:
      'Предоставената информация е публична и не претендира да е вярна. В случай, че информацията се окаже невярна, MySupermarket не дължи вреди на никого.',
  },
};

export const toNumber = value =>
  i18n.toNumber(value, {
    delimiter: ',',
    separator: '.',
    precision: 2,
    strip_insignificant_zeros: true,
  });

export const toPercentage = value =>
  i18n.toPercentage(value, {
    delimiter: ',',
    separator: '.',
    precision: 0,
    strip_insignificant_zeros: true,
  });

export function weightUnit(
  productVariant,
  multiplier = 1,
  { showTotal = false } = {}
) {
  if (!productVariant.weight) return;

  const totalQuantityPerUnit =
    // multiplier is used in SavingsScreen to format for the total quantity added in cart
    multiplier *
    // the base total quantity is the offering quantity + quantityPerUnit
    (((productVariant.offering.offerQuantity > 1 &&
      isActiveOffering(productVariant.offering) &&
      productVariant.offering.offerQuantity) ||
      1) *
      (productVariant.quantityPerUnit || 1));

  let weight, unit;

  if (showTotal) {
    ({ weight, unit } = prettyWeightUnit(
      productVariant.weight,
      productVariant.unit,
      totalQuantityPerUnit
    ));
  } else {
    ({ weight, unit } = prettyWeightUnit(
      productVariant.weight,
      productVariant.unit
    ));
  }

  // units
  // if (productVariant.unit === UNITS.UNIT) {
  //   if (totalQuantityPerUnit > 1) {
  //     // show only if it's more than one
  //     // otherwise it is kinda duplicated with the unit price
  //     return `(${i18n.t(`units.${unit}`, { count: totalQuantityPerUnit })})`;
  //   }
  //   return null;
  // }

  // 1.5l, 330ml, 1kg, 250g...
  let weightStr = i18n.t(`units.${unit}`, { count: toNumber(weight) });

  if (totalQuantityPerUnit > 1 && !showTotal) {
    // 2x100g, 3x330ml. etc...
    weightStr = `${totalQuantityPerUnit}x${weightStr}`;
  }

  return weightStr;
}

export const getFormattedOfferOrDiscount = offering => {
  if (!getSpecialPrice(offering)) {
    // no special price
    return null;
  }

  // pack of `offerQuantity`
  if (offering.offerQuantity > 1) {
    return i18n.t(`offer.multi`, {
      offerQuantity: offering.offerQuantity,
      price: toNumber(offering.specialPrice),
    });
  }

  if (offering.specialPrice === offering.price) {
    // special price is the same
    return null;
  }

  // percentage discount
  return toPercentage(
    getPriceDiscountPercentage(offering.price, offering.specialPrice)
  );
};

export const getFormattedUnitPrice = productVariant => {
  const formattedUnitPrice = i18n.t('price', {
    price: toNumber(productVariant.offering.unitPrice),
  });
  const singleUnit = i18n.t(`units.${productVariant.unit}`, { count: 0 });

  return `${formattedUnitPrice}/${singleUnit}`;
};

export const t = i18n.t;
