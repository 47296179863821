import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import Modal from '../Modal';
import Button from '../Button';
import {
  useConfig,
  useUser,
  SubscriptionPlan,
  useApi,
  useOrders,
  useCancelSubscription,
} from '@mm/common';
import classNames from 'classnames';
import {
  CheckCircleIcon,
  CheckIcon,
  StarIcon,
} from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { getStripe } from '@/utils/stripe';
import dayjs from 'dayjs';

export default function PaymentModal() {
  const { paymentModalIsOpen, setPaymentModalIsOpen } = useOrders();

  return (
    <Modal
      className="max-w-2xl p-6"
      isOpen={paymentModalIsOpen}
      onClose={() => {
        setPaymentModalIsOpen(false);
      }}
    >
      <>
        <h3 className="text-md font-bold leading-6 mb-6">Абонамент</h3>
        <PaymentModalInner />
      </>
    </Modal>
  );
}

function PaymentModalInner() {
  const { data: user } = useUser();
  const { data } = useConfig();
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>();
  const useCancelSubscriptionMutation = useCancelSubscription();

  // Auto-select PREMIUM plan
  useEffect(() => {
    if (!data || selectedPlan) return;

    setSelectedPlan(data.plans.PREMIUM);
  }, [data, selectedPlan]);

  if (!user || !data) return null;

  const currentPlan = data.plans[user.subscription.planId];

  return (
    <>
      <div className="flex space-x-8 mt-4">
        {Object.entries(data.plans).map(([key, plan]) => {
          const isCurrentPlan = key === user.subscription.planId;
          const isSelectedPlan = selectedPlan === plan;

          return (
            <div key={key} className="py-6 px-2 w-1/2">
              <div
                className={classNames(
                  'bg-white rounded-xl space-y-6 overflow-hidden transition-all duration-500 transform -translate-y-2 scale-105 shadow-xl relative',
                  {
                    // 'cursor-pointer hover:-translate-y-6 hover:shadow-2xl':
                    //   !isSelectedPlan,
                    'shadow-2xl shadow-primary-200': isSelectedPlan,
                  }
                )}
                // onClick={async () => {
                //   setSelectedPlan(plan);
                // }}
              >
                <div className="py-4 px-2 text-center">
                  <div className="px-8 flex justify-center items-center space-x-2">
                    <h4 className="text-xl font-semibold text-slate-500">
                      {key}
                    </h4>
                    {plan.id === 'PREMIUM' && (
                      <StarIcon className="h-7 fill-secondary-400" />
                    )}
                  </div>
                  <h1 className="my-2 text-2xl text-center font-bold">
                    {plan.amount > 0
                      ? `${plan.amount}лв. / месец`
                      : 'Безплатен'}
                  </h1>
                  <ul className="text-center">
                    <li>
                      <span className="text-slate-600">
                        Запазени търсения:{' '}
                      </span>
                      <span className="font-semibold">
                        {plan.terms || 'Неограничени'}
                      </span>
                    </li>
                    {plan.trialPeriod && (
                      <li>
                        <span className="text-slate-600">Пробен период: </span>
                        <span className="font-semibold">
                          {plan.trialPeriod} дни
                        </span>
                      </li>
                    )}
                  </ul>
                  {isCurrentPlan && (
                    <div className="text-xs font-semibold text-gray-500 underline mt-3">
                      <CheckIcon className="inline-block h-4 fill-green-400" />
                      {user.subscription.status === 'active' ||
                      user.subscription.status === 'trialing' ||
                      currentPlan.id === 'FREE'
                        ? 'Активен'
                        : user.subscription.status}
                      {user.subscription.expiration &&
                        ` (до ${dayjs(user.subscription.expiration).format(
                          'DD/MM/YYYY'
                        )})`}
                    </div>
                  )}
                </div>
                {isCurrentPlan &&
                  currentPlan.amount > 0 &&
                  user.subscription.status !== 'canceled' && (
                    <Button
                      className="block w-full rounded-t-none border-none"
                      variant="outline"
                      onClick={() => {
                        useCancelSubscriptionMutation.trigger();
                      }}
                      disabled={useCancelSubscriptionMutation.isMutating}
                      loading={useCancelSubscriptionMutation.isMutating}
                      confirm
                      textClassName="!text-red-600"
                    >
                      Спри Абонамент
                    </Button>
                  )}
              </div>
            </div>
          );
        })}
      </div>

      {selectedPlan &&
        selectedPlan?.amount > 0 &&
        selectedPlan !== currentPlan && (
          <Elements
            stripe={getStripe()}
            options={{
              mode: 'subscription',
              currency: 'bgn',
              amount: selectedPlan.amount * 100,
              // Fully customizable with appearance API.
              appearance: {},
            }}
          >
            <CheckoutForm plan={selectedPlan} />
          </Elements>
        )}
    </>
  );
}

const CheckoutForm = ({ plan }: { plan?: SubscriptionPlan }) => {
  const stripe = useStripe();
  const elements = useElements();
  const api = useApi();

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const handleError = error => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements || !plan) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    try {
      const { type, clientSecret } = await api.post(
        '/v1/orders/subscription',
        true,
        {
          planId: plan.id,
        }
      );
      const confirmIntent =
        type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment;

      // Confirm the Intent using the details collected by the Payment Element
      const { error } = await confirmIntent({
        elements,
        clientSecret,
        confirmParams: {
          return_url: window.location.href,
          // return_url: `${window.location.origin}/subscription`,
        },
      });

      if (error) {
        // This point is only reached if there's an immediate error when confirming the Intent.
        // Show the error to your customer (for example, "payment details incomplete").
        handleError(error);
      } else {
        // Your customer is redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer is redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <form onSubmit={handleSubmit} id="payment-form" className="m-auto">
      <PaymentElement
        options={{
          layout: {
            type: 'tabs',
            defaultCollapsed: false,
          },
        }}
      />
      <Button className="my-2" loading={loading} disabled={loading}>
        Активирай
      </Button>
      {errorMessage && <p className="my-1 text-red-600">{errorMessage}</p>}
    </form>
  );
};
