import { CheckCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React, { PropsWithChildren, ReactElement } from 'react';

export default function Alert({
  children,
  info,
  success,
  error,
  warning,
  icon,
  title,
}: PropsWithChildren<{
  info?: boolean;
  success?: boolean;
  error?: boolean;
  warning?: boolean;
  icon?: boolean | ReactElement;
  title?: string | ReactElement;
}>) {
  const getIcon = () => {
    if (icon === false) {
      return null;
    }
    if (React.isValidElement(icon)) {
      return icon;
    }
    if (success) {
      return <CheckCircleIcon className="h-8 float-left mr-4" />;
    }
    return null;
  };

  return (
    <div
      className={classNames('p-4 mb-4 text-sm rounded-lg', {
        // 'text-blue-800 bg-blue-50 dark:bg-gray-800 dark:text-blue-400': info,
        'text-blue-800 bg-blue-50': info,
        // 'text-red-800 bg-red-50 dark:bg-gray-800 dark:text-red-400': error,
        'text-red-800 bg-red-50': error,
        // 'text-green-800 bg-green-50 dark:bg-gray-800 dark:text-green-400':
        //   success,
        'text-green-800 bg-green-50': success,
        // 'text-yellow-800 bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300':
        //   warning,
        'text-yellow-800 bg-yellow-50': warning,
      })}
      role="alert"
    >
      {getIcon()}
      <div>
        {title && <h4 className="font-semibold">{title}</h4>}
        {children}
      </div>
    </div>
  );
}
