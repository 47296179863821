import { useAuth } from '@mm/common/auth/useAuth';
import useSWR from 'swr';
import { useApi } from '../api/ApiProvider';
import { IShopId } from './shops';
import { Types } from 'mongoose';
import useSWRMutation from 'swr/mutation';
import { IUser } from './user';

export type FavSearch = {
  id: string;
  term: string;
  category: string;
  shops?: IShopId[] | null;
  user: Types.ObjectId | IUser;
  lastViewed: string;
  newSinceLastViewed: number;
};

export const getFavorites = api => () => api.get('/v1/favorites', true);

export function useFavorites() {
  const api = useApi();
  const { isLoggedIn } = useAuth();

  const { data, isLoading, mutate } = useSWR<FavSearch[]>(
    isLoggedIn ? '/v1/favorites' : null,
    getFavorites(api)
  );

  return {
    data,
    isLoading,
    mutate,
    totalUnread: data?.reduce((acc, d) => acc + d.newSinceLastViewed, 0) ?? 0,
    findFavorite: (
      termFilter: string,
      shopsFilter: IShopId[] = [],
      categoryFilter?: string
    ) =>
      data?.find(
        fav =>
          fav.term === termFilter &&
          shopsFilter.every(s => fav.shops.includes(s)) &&
          fav.category === categoryFilter
      ),
    upsertFavorite: async (
      id: string | undefined,
      term: string,
      shops: string[] = [],
      category?: string
    ) => {
      const res = (await api.post('/v1/favorites', true, {
        id,
        term,
        shops,
        category,
      })) as FavSearch;
      mutate(
        id ? data.map(d => (d.id === res.id ? res : d)) : data.concat(res),
        {
          revalidate: false,
        }
      );
    },
    removeFavorite: async (id: string) => {
      await api.remove(`/v1/favorites/${id}`);
      mutate();
    },
    markRead: useSWRMutation(
      '/v1/favorites',
      (url, { arg }: { arg: { id: string } }) =>
        api.post(`/v1/favorites/${arg.id}/read`, true, arg)
    ),
  };
}
