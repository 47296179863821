import useSWRImmutable from 'swr/immutable';
import { useApi } from '../api/ApiProvider';
import { IUser } from './user';
import type { SubscriptionPlan } from './orders';

export type Config = {
  user?: IUser;
  plans: {
    [K in 'FREE' | 'PREMIUM']: SubscriptionPlan;
  };
};

export function useConfig() {
  const api = useApi();
  const swr = useSWRImmutable<Config>(['/config'], () => api.get(`/v1/config`));

  return swr;
}
